import React from 'react'
import ESC_CONTENT from '../../../components/shared/modals/esc-content'
import Layout from '../../../components/layout'

const ESC_GUARDIAN = () => (
  <Layout>
    <ESC_CONTENT region="GUARDIAN" />
  </Layout>
)

export default ESC_GUARDIAN
